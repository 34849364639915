import styled from '@emotion/styled';
import mq from '../utils/breakpoints';
import earthSvg from '../../content/earth-opt-01.svg';

export const ShowcaseContainer = styled('section')`
  ${mq('iphoneSE', 'min')} {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #072a42;
    padding: 3rem 0;
    z-index: 0;
  }

  ${mq('tabletLg', 'min')} {
    flex-direction: row-reverse;
    align-items: center;
  }
`;

export const ShowcaseTitle = styled('h2')`
  ${mq('iphoneSE', 'min')} {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    color: #fff;
    text-align: center;
    padding: 1rem;
  }

  ${mq('tabletLg', 'min')} {
    font-size: 1.5rem;
    margin: 3rem 0;
  }
  ${mq('desktop', 'min')} {
    font-size: 1.8rem;
  }
`;

export const ShowcaseTextContainer = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  ${mq('tabletLg', 'min')} {
    justify-content: center;
    min-height: 650px;
    width: 50%;
    margin: auto 0;
  }
`;

export const ShowcaseDescription = styled('p')`
  ${mq('iphoneSE', 'min')} {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    padding: 1.2rem;
  }

  ${mq('tabletLg', 'min')} {
    font-size: 1rem;
  }
  ${mq('desktop', 'min')} {
    font-size: 1.2rem;
  }
`;

export const IconsContainer = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  ${mq('tabletLg', 'min')} {
    flex-direction: row;
    margin: 5rem 0 0 0;
  }
`;

export const IconWrapper = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-size: 1rem;
    padding: 0.75rem;
    i {
      font-size: 2.5rem;
      margin-bottom: 0.75rem;
    }
    p {
      margin-bottom: 0.4rem;
    }
  }

  ${mq('tabletLg', 'min')} {
    i {
      font-size: 2rem;
    }
    p {
      text-align: center;
      font-size: 0.8rem;
    }
  }
`;

export const ShowcaseChevron = styled('button')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem auto 0 auto;
    color: #fff;
    p {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }

    i {
      font-size: 2.5rem;
    }
  }
`;

export const SvgContainer = styled('div')`
  ${mq('iphoneSE', 'min')} {
    width: 300px;
    height: 268.563px;
    margin: 1rem auto;
    overflow: hidden;
  }

  ${mq('tabletLg', 'min')} {
    width: 500px;
    height: 440.267px;
    margin: 1rem;
  }

  ${mq('desktop', 'min')} {
    width: 845px;
    height: 744.051px;
  }
`;

export const SvgImage = styled(earthSvg)`
  width: 100%;
  height: 100%;
`;

export const DarkenedBg = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;
