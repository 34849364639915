import React from 'react';
import { CSSTransition } from 'react-transition-group';

import {
  DiversityContainer,
  DiversityGroupContainer,
  DiversityDuo,
  IconContainer,
  IconCircle,
  IconForCircle,
  Icon
} from '../../styles/diversityStyles';

import {
  GeneralContainerTitle,
  GeneralBorderContainer,
  Border,
  Circle
} from '../../styles/generalStyles';

const Diversity = () => {
  return (
    <DiversityContainer>
      <GeneralContainerTitle>
        Security Solutions For Every Industry
      </GeneralContainerTitle>
      <GeneralBorderContainer>
        <CSSTransition
          in={true}
          appear={true}
          timeout={800}
          classNames={'scale'}
        >
          <Border
            style={{
              transformOrigin: 'right'
            }}
          />
        </CSSTransition>
        <CSSTransition
          in={true}
          appear={true}
          timeout={800}
          classNames={'scale'}
        >
          <Circle />
        </CSSTransition>
        <CSSTransition
          in={true}
          appear={true}
          timeout={800}
          classNames={'scale'}
        >
          <Border
            style={{
              transformOrigin: 'left'
            }}
          />
        </CSSTransition>
      </GeneralBorderContainer>
      <DiversityGroupContainer>
        <DiversityDuo>
          <IconContainer>
            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              classNames={'bounce'}
            >
              <Icon
                className="fas fa-heartbeat"
                style={{
                  color: '#b02b2c'
                }}
              />
            </CSSTransition>
            <p>Health</p>
          </IconContainer>
          <IconContainer>
            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              classNames={'bounce'}
            >
              <Icon
                className="fas fa-landmark"
                style={{
                  color: '#2ea527'
                }}
              />
            </CSSTransition>
            <p>Finance</p>
          </IconContainer>
        </DiversityDuo>
        <DiversityDuo>
          <IconContainer>
            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              classNames={'bounce'}
            >
              <Icon
                className="fas fa-tag"
                style={{
                  color: '#4392e8'
                }}
              />
            </CSSTransition>
            <p>Retail</p>
          </IconContainer>
          <IconContainer>
            <IconCircle>
              <CSSTransition
                in={true}
                appear={true}
                timeout={500}
                classNames={'bounce'}
              >
                <IconForCircle className="fab fa-btc" />
              </CSSTransition>
            </IconCircle>
            <p>Blockchain</p>
          </IconContainer>
        </DiversityDuo>
        <DiversityDuo>
          <IconContainer>
            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              classNames={'bounce'}
            >
              <Icon
                className="fas fa-graduation-cap"
                style={{
                  color: '#7bb0e7'
                }}
              />
            </CSSTransition>
            <p>Education</p>
          </IconContainer>
          <IconContainer>
            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              classNames={'bounce'}
            >
              <Icon
                className="fab fa-youtube"
                style={{
                  color: '#b02b2c'
                }}
              />
            </CSSTransition>
            <p>Media</p>
          </IconContainer>
        </DiversityDuo>
        <DiversityDuo>
          <IconContainer>
            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              classNames={'bounce'}
            >
              <Icon
                className="fas fa-shopping-cart"
                style={{
                  color: '#61227c'
                }}
              />
            </CSSTransition>

            <p>E-Commerce</p>
          </IconContainer>
          <IconContainer>
            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              classNames={'bounce'}
            >
              <Icon
                className="fas fa-cog"
                style={{
                  color: '#8c8c8c'
                }}
              />
            </CSSTransition>
            <p>Manufacturing</p>
          </IconContainer>
        </DiversityDuo>
      </DiversityGroupContainer>
      <IconContainer>
        <CSSTransition
          in={true}
          appear={true}
          timeout={500}
          classNames={'bounce'}
        >
          <Icon
            className="fas fa-desktop"
            style={{
              color: '#8c8c8c'
            }}
          />
        </CSSTransition>
        <p>Technology</p>
      </IconContainer>
    </DiversityContainer>
  );
};

export default Diversity;
