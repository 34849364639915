import React from 'react';
import {
  ShowcaseContainer,
  ShowcaseTextContainer,
  ShowcaseTitle,
  ShowcaseDescription,
  ShowcaseChevron,
  IconsContainer,
  SvgContainer,
  SvgImage,
  IconWrapper,
  DarkenedBg
} from '../../styles/showcaseStyles';

const Showcase = props => {
  return (
    <ShowcaseContainer>
      <SvgContainer>
        <SvgImage />
      </SvgContainer>
      <ShowcaseTextContainer>
        <ShowcaseTitle>
          Cyber Attacks are incresing every day, is your team prepared?
        </ShowcaseTitle>
        <ShowcaseDescription>
          With our expertice and experience, OneCloudSecurity is at latest in cyber
          security technologies.
        </ShowcaseDescription>
        <IconsContainer>
          <IconWrapper>
            <i className="far fa-chart-bar" />
            <p>Threat intelligence feedback</p>
          </IconWrapper>
          <IconWrapper>
            <i className="fas fa-thumbs-up" />
            <p>Pentesting</p>
            <p>Remediation Testing</p>
          </IconWrapper>
          <IconWrapper>
            <i className="fas fa-tools" />
            <p>OneCloudSecurity Created</p>
            <p>Open Source Security Tools</p>
          </IconWrapper>
        </IconsContainer>
        <ShowcaseChevron onClick={() => props.handleScroll()}>
          Find Out More
          <i className="fas fa-chevron-down" />
        </ShowcaseChevron>
      </ShowcaseTextContainer>
      <DarkenedBg />
    </ShowcaseContainer>
  );
};

export default Showcase;

// #072a42
