import React, { forwardRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import {
  ServicesContainer,
  ServicesCard,
  ServicesTitle,
  ServicesDescription,
  ServicesDescriptionThree,
  SvgContainer,
  GroupContainer,
  BlackBoxSvg,
  WhiteBoxSvg,
  IntelligenceSvg
} from '../../styles/servicesStyles';

import {
  GeneralContainerTitle,
  GeneralBorderContainer,
  Border,
  Circle
} from '../../styles/generalStyles';

const Services = (props, ref) => {
  return (
    <ServicesContainer ref={ref}>
      <GeneralContainerTitle>Our Most Popular Services</GeneralContainerTitle>
      <GeneralBorderContainer>
        <CSSTransition
          in={true}
          appear={true}
          timeout={800}
          classNames={'scale'}
        >
          <Border
            style={{
              transformOrigin: 'right'
            }}
          />
        </CSSTransition>
        <CSSTransition
          in={true}
          appear={true}
          timeout={800}
          classNames={'scale'}
        >
          <Circle />
        </CSSTransition>
        <CSSTransition
          in={true}
          appear={true}
          timeout={800}
          classNames={'scale'}
        >
          <Border
            style={{
              transformOrigin: 'left'
            }}
          />
        </CSSTransition>
      </GeneralBorderContainer>
      <GroupContainer>
        <ServicesCard>
          <SvgContainer>
            <BlackBoxSvg />
          </SvgContainer>
          <ServicesTitle>Black Box Network Penetration Testing</ServicesTitle>
          <ServicesDescription>
            OneCloudSecurity's Black Box Network Penetration Testing identifies and
            exploits vulnerabilities within your cloud, network and server
            infrastructure. Our procedure involves combining several
            specifically tailored exploits and attacks to gain privileged access
            to network systems and confidential data.
          </ServicesDescription>
        </ServicesCard>
        <ServicesCard>
          <SvgContainer>
            <IntelligenceSvg />
          </SvgContainer>
          <ServicesTitle>Threat Intelligence</ServicesTitle>
          <ServicesDescription>
            OneCloudSecurity's threat intelligence services accelerate the
            transformation of information system data into actionable threat
            intelligence by combining external and internal data sources for
            context and prioritization. Our threat intelligence services deliver
            comprehensive, actionable intelligence that allows you to understand
            attackers intent and quickly prioritize threats.
          </ServicesDescription>
        </ServicesCard>
      </GroupContainer>
      <ServicesCard>
        <SvgContainer>
          <WhiteBoxSvg />
        </SvgContainer>
        <ServicesTitle>White Box Network Penetration Testing</ServicesTitle>
        <ServicesDescriptionThree>
          OneCloudSecurity's White Box Network Penetration Testing attempts to identify
          technical, architectural and logical design vulnerabilities that
          contribute to the risk profile of your organizations information
          systems. Our process utilizes both OWASP v4 and PTES guidelines, which
          results in a robust testing framework that spans over 80+ testing
          procedures.
        </ServicesDescriptionThree>
      </ServicesCard>
    </ServicesContainer>
  );
};

const ForwardServices = forwardRef(Services);

export default ForwardServices;
