import styled from '@emotion/styled';
import mq from '../utils/breakpoints';

export const GeneralContainerTitle = styled('h2')`
  ${mq('iphoneSE', 'min')} {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  ${mq('tablet', 'min')} {
    font-size: 1.8rem;
  }
`;

export const GeneralBorderContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Border = styled('div')`
  ${mq('iphoneSE', 'min')} {
    width: 100px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.7);
    transform: scaleX(0);

    &.scale-appear-enter,
    &.scale-enter {
      transform: scaleX(0);
    }

    &.scale-appear-active,
    &.scale-enter-active {
      transform: scaleX(1);
      transition: all 800ms ease-in-out;
    }

    &.scale-appear-done,
    &.scale-enter-done {
      transform: scaleX(1);
    }
  }
`;

export const Circle = styled('div')`
  width: 10px;
  height: 10px;
  border: 2px solid rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  tranform-origin: center;
  transform: scale(0);

  &.scale-appear-enter,
  &.scale-enter {
    transform: scaleX(0);
  }

  &.scale-appear-active,
  &.scale-enter-active {
    transform: scaleX(1);
    transition: all 800ms ease-in-out;
  }

  &.scale-appear-done,
  &.scale-enter-done {
    transform: scaleX(1);
  }
`;
