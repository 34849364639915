import styled from '@emotion/styled';
import mq from '../utils/breakpoints';

export const DiversityContainer = styled('section')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
`;

export const DiversityDuo = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 1rem auto;
    width: 100%;
    height: 100%;
  }
  ${mq('iPadPro', 'min')} {
    flex-direction: column;
  }
`;

export const DiversityGroupContainer = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  ${mq('iPadPro', 'min')} {
    flex-direction: row;
  }
`;

export const IconContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  font-size: 1.1rem;
  margin: 0 1rem;

  p {
    margin-top: 1rem;
  }

  ${mq('iPadPro', 'min')} {
    margin-bottom: 2.5rem;
  }
`;

export const Icon = styled('i')`
  font-size: 1.5rem;
  transform: scale(1);

  &.bounce-appear-enter,
  &.bounce-enter {
    transform: scale(1);
  }
  &.bounce-appear-active,
  &.bounce-enter-active {
    transform: scale(1.1);
    transition: all 500ms ease-in-out;
  }
  &.bounce-appear-done,
  &.bounce-enter-done {
    transform: scale(1);
  }
`;

export const IconForCircle = styled('i')`
  font-size: 1.1rem !important;
  transform: scale(1);

  &.bounce-appear-enter,
  &.bounce-enter {
    transform: scale(1);
  }
  &.bounce-appear-active,
  &.bounce-enter-active {
    transform: scale(1.1);
    transition: all 500ms ease-in-out;
  }
  &.bounce-appear-done,
  &.bounce-enter-done {
    transform: scale(1);
  }
`;

export const IconCircle = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0.3rem;
    color: #fff;
    transform: rotate(22deg);
    background-color: #edae44;
    line-height: 1.5;
  }
`;
