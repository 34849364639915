import React from 'react';
import Layout from '../components/layout';
import Showcase from '../components/showcase/Showcase';
import Navbar from '../components/nav/Navbar';
import Services from '../components/main/Services';
import Diversity from '../components/main/Diversity';
import Companies from '../components/main/Companies';
import Footer from '../components/main/Footer';

import useScroll from '../hooks/useScroll';

const LandingPage = () => {
  const [serviceRef, handleScroll] = useScroll();

  return (
    <Layout>
      <Navbar />
      <Showcase handleScroll={handleScroll} />
      <Services ref={serviceRef} />
      <Diversity />
      <Companies />
      <Footer />
    </Layout>
  );
};

export default LandingPage;
