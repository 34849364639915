import { useRef } from 'react';

const useScroll = () => {
  const htmlRef = useRef(null);
  const executeScroll = () => {
    htmlRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  };

  return [htmlRef, executeScroll];
};

export default useScroll;
