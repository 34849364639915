import React from 'react';

import {
  FooterContainer,
  FooterComponent,
  Title,
  FooterDescription,
  ContentPrivacy,
  DarkenedBg
} from '../../styles/footerStyles';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterComponent>
        <Title>Our Standard Office Hours</Title>
        <FooterDescription>
          Monday-Friday: 8:00AM - 5:00PM EDT <br />
          Saturday-Sunday: Closed
        </FooterDescription>
      </FooterComponent>
      <FooterComponent>
        <Title>Where To Contact Us</Title>
        <FooterDescription>
          You can contact us at: <br />
          info@onecloudsecurity.com
        </FooterDescription>
      </FooterComponent>
      <FooterComponent>
        <Title>Content Privacy Notice</Title>
        <ContentPrivacy>
          <li>
            – All product names, logos, and brands are property of their
            respective owners.
          </li>
          <li>
            – The use of these names, logos, and brands is for identification
            purposes only and does not imply endorsement.
          </li>
          <li>
            – Content syndication and aggregation of public information is
            solely for the purpose of identifying information security trends,
            all syndicated content contains source links to the content creator
            website. All content is owned by it’s respective content creators.
          </li>
          <li>
            – If you are an owner of some content and want it to be removed,
            please email info@onecloudsecurity.com
          </li>
        </ContentPrivacy>
      </FooterComponent>
      <DarkenedBg />
    </FooterContainer>
  );
};

export default Footer;
