import React from 'react';
import { NavbarContainer, NavbarTitle } from '../../styles/navbarStyles';

const Navbar = () => {
  return (
    <NavbarContainer>
      <NavbarTitle href={'/'}>
        <span>OneCloud</span>Security
      </NavbarTitle>
    </NavbarContainer>
  );
};

export default Navbar;
