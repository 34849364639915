import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { CSSTransition } from 'react-transition-group';

import {
  CompaniesContainer,
  CompanyGroupContainer,
  CompanyLogoContainer,
  CompanyDuo
} from '../../styles/companiesStyles';

import {
  GeneralContainerTitle,
  GeneralBorderContainer,
  Border,
  Circle
} from '../../styles/generalStyles';

const Companies = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "company-logos" } }) {
        edges {
          node {
            childImageSharp {
              id
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <CompaniesContainer>
      <GeneralContainerTitle>Trusted By</GeneralContainerTitle>
      <GeneralBorderContainer>
        <CSSTransition
          in={true}
          appear={true}
          timeout={800}
          classNames={'scale'}
        >
          <Border
            style={{
              transformOrigin: 'right'
            }}
          />
        </CSSTransition>
        <CSSTransition
          in={true}
          appear={true}
          timeout={800}
          classNames={'scale'}
        >
          <Circle />
        </CSSTransition>
        <CSSTransition
          in={true}
          appear={true}
          timeout={800}
          classNames={'scale'}
        >
          <Border
            style={{
              transformOrigin: 'left'
            }}
          />
        </CSSTransition>
      </GeneralBorderContainer>
      <CompanyGroupContainer>
        {data.allFile.edges.map((obj, index) => {
          if (index % 2 === 0) {
            return (
              <CompanyDuo key={obj.node.childImageSharp.id}>
                <CompanyLogoContainer key={obj.node.childImageSharp.id}>
                  <Img fluid={obj.node.childImageSharp.fluid} />
                </CompanyLogoContainer>
                <CompanyLogoContainer>
                  <Img
                    fluid={
                      data.allFile.edges[index + 1].node.childImageSharp.fluid
                    }
                  />
                </CompanyLogoContainer>
              </CompanyDuo>
            );
          } else {
            return null;
          }
        })}
      </CompanyGroupContainer>
    </CompaniesContainer>
  );
};

export default Companies;
