import styled from '@emotion/styled';
import mq from '../utils/breakpoints';

export const NavbarContainer = styled('nav')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 1rem;
  }
  ${mq('tablet', 'min')} {
    justify-content: flex-start;
    margin-right: 1rem;
  }
  ${mq('tabletLg', 'min')} {
    margin-right: 2rem;
  }
`;

export const NavbarTitle = styled('a')`
  span {
    color: #1a8be9;
  }
  ${mq('iphoneSE', 'min')} {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 1.2rem;
    text-align: center;
    color: #000;
  }

  ${mq('tablet', 'min')} {
    font-size: 2rem;
  }

  ${mq('tabletLg', 'min')} {
    font-size: 2.2rem;
  }
`;
