import styled from '@emotion/styled';
import mq from '../utils/breakpoints';

export const FooterContainer = styled('footer')`
  ${mq('iphoneSE', 'min')} {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #072a42;
    z-index: 0;
  }

  ${mq('iPadPro', 'min')} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const FooterComponent = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem 0;
    width: 100%;
  }
`;

export const Title = styled('h3')`
  ${mq('iphoneSE', 'min')} {
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    color: #efefef;
    margin-bottom: 1rem;
    margin-left: 0.75rem;
  }
`;
export const FooterDescription = styled('p')`
  ${mq('iphoneSE', 'min')} {
    font-size: 0.9rem;
    color: #fff;
    margin-left: 0.75rem;
  }
`;

export const ContentPrivacy = styled('ul')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    li {
      color: #fff;
      margin: 0.75rem 1rem;
      padding: 0 1rem;
    }
  }
`;

export const DarkenedBg = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;
