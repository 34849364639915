import styled from '@emotion/styled';
import mq from '../utils/breakpoints';
import blackboxSvg from '../../content/blackbox-opt-02.svg';
import intelligenceSvg from '../../content/intelligence-opt-02.svg';
import whiteboxSvg from '../../content/whitebox-opt-01.svg';

export const ServicesContainer = styled('section')`
  width: 100%;
  margin: 4rem auto;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ServicesContainerTitle = styled('h2')`
  ${mq('iphoneSE', 'min')} {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  ${mq('tablet', 'min')} {
    font-size: 1.8rem;
  }
`;

export const ServicesBorderContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Border = styled('div')`
  ${mq('iphoneSE', 'min')} {
    width: 100px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.7);
    transform: scaleX(0);

    &.scale-appear-enter,
    &.scale-enter {
      transform: scaleX(0);
    }

    &.scale-appear-active,
    &.scale-enter-active {
      transform: scaleX(1);
      transition: all 800ms ease-in-out;
    }

    &.scale-appear-done,
    &.scale-enter-done {
      transform: scaleX(1);
    }
  }
`;

export const Circle = styled('div')`
  width: 10px;
  height: 10px;
  border: 2px solid rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  tranform-origin: center;
  transform: scale(0);

  &.scale-appear-enter,
  &.scale-enter {
    transform: scaleX(0);
  }

  &.scale-appear-active,
  &.scale-enter-active {
    transform: scaleX(1);
    transition: all 800ms ease-in-out;
  }

  &.scale-appear-done,
  &.scale-enter-done {
    transform: scaleX(1);
  }
`;

export const ServicesCard = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 3rem auto;
  }
`;

export const ServicesTitle = styled('h2')`
  ${mq('iphoneSE', 'min')} {
    width: 100%;
    padding: 0 0.6rem;
    text-align: center;
    font-size: 1.3rem;
    font-family: 'Lato', sans-serif;
    color: rgba(0, 0, 0, 0.9);
  }

  ${mq('phoneLg', 'min')} {
    font-size: 1.5rem;
  }
`;

export const ServicesDescription = styled('p')`
  ${mq('iphoneSE', 'min')} {
    width: 100%;
    padding: 0 3rem;
    margin: 2rem;
    font-size: 1rem;
    text-align: center;
  }

  ${mq('phoneLg', 'min')} {
    font-size: 1.3rem;
  }

  ${mq('tablet', 'min')} {
    padding: 0 8rem;
    margin: 2rem 4rem;
  }

  ${mq('desktop', 'min')} {
    padding: 0 4rem;
  }
`;
export const ServicesDescriptionThree = styled('p')`
  ${mq('iphoneSE', 'min')} {
    width: 100%;
    padding: 0 3rem;
    margin: 2rem;
    font-size: 1rem;
    text-align: center;
  }

  ${mq('phoneLg', 'min')} {
    font-size: 1.3rem;
  }

  ${mq('tablet', 'min')} {
    padding: 0 8rem;
    margin: 2rem 4rem;
  }

  ${mq('desktop', 'min')} {
    padding: 0 18rem;
  }
`;

export const SvgContainer = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 1rem;
    width: 200px;
  }
`;

export const GroupContainer = styled('div')`
  ${mq('desktop', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
`;

export const BlackBoxSvg = styled(blackboxSvg)`
  width: 100%;
  height: 100%;
`;

export const IntelligenceSvg = styled(intelligenceSvg)`
  width: 100%;
  height: 100%;
`;

export const WhiteBoxSvg = styled(whiteboxSvg)`
  width: 100%;
  height: 100%;
`;
