import styled from '@emotion/styled';
import mq from '../utils/breakpoints';

export const CompaniesContainer = styled('section')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;
  }
`;

export const CompanyDuo = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 1rem auto;
    width: 100%;
    height: 100%;
  }
  ${mq('iPadPro', 'min')} {
    flex-direction: column;
  }
`;

export const CompanyGroupContainer = styled('div')`
  ${mq('iphoneSE', 'min')} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  ${mq('iPadPro', 'min')} {
    flex-direction: row;
  }
`;

export const CompanyLogoContainer = styled('div')`
  ${mq('iphoneSE', 'min')} {
    width: 110px;
    height: 100%;
    margin: 2rem;
  }

  ${mq('tablet', 'min')} {
    width: 150px;
  }
`;
