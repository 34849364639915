const breakpoints = {
  iphoneSE: "300",
  small: "375",
  phoneLg: "650",
  tablet: "768",
  tabletLg: "992",
  iPadPro: "1024",
  desktop: "1200",
  desktopLg: "1600",
};

function mq(device, bounds) {
  const bpArray = Object.keys(breakpoints).map(function bpValues(bpKey) {
    return [bpKey, breakpoints[bpKey]];
  });

  const [result] = bpArray.reduce(function makeQuery(acc, [name, size]) {
    if (name === device) {
      return [...acc, `@media screen and (${bounds}-width: ${size}px)`];
    }
    return acc;
  }, []);

  return result;
}

export default mq;
