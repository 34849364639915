import React from 'react';
import Helmet from 'react-helmet';
import { Global, css } from '@emotion/core';
import useSiteMetaData from '../hooks/useSiteMetaData';

const Layout = ({ children }) => {
  const { title, author, description } = useSiteMetaData();
  return (
    <>
      <Global
        styles={css`
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            overflow-x: hidden;
          }

          html,
          body {
            font-family: 'Roboto', sans-serif;
            font-size: 1rem;
            background-color: #f4f4f4;
          }

          body {
            position: relative;
          }

          a {
            text-decoration: none;
            cursor: pointer;
          }

          li {
            list-style: none;
          }

          button {
            border: none;
            cursor: pointer;
            background-color: transparent;
          }

          input,
          textarea {
            border: none;
          }
        `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="author" content={author} />
        <meta name="description" content={description} />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Lato:900|Roboto&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.11.2/css/all.css"
        />
      </Helmet>
      {children}
    </>
  );
};

export default Layout;

/*


          #___gatsby {
            height: 100%;
            overflow-x: hidden;
          }



          div[role="group"][tabindex] {
            height: 100%;
            overflow-x: hidden;
          }

*/
